/* Helvetica Neue, replaced by Arimo https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap */
@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-display: swap;
  src: local("Helvetica Neue"), local("HelveticaNeue"),
    url(https://fonts.gstatic.com/s/arimo/v13/P5sMzZCDf9_T_10XxCF8jA.woff2)
      format("woff2");
  /* latin-ext */
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-display: swap;
  src: local("Helvetica Neue"), local("HelveticaNeue"),
    url(https://fonts.gstatic.com/s/arimo/v13/P5sMzZCDf9_T_10ZxCE.woff2)
      format("woff2");
  /* latin */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Helvetica Neue Bold"), local("HelveticaNeue-Bold"),
    url(https://fonts.gstatic.com/s/arimo/v13/P5sBzZCDf9_T_1Wi4TRNrZKX0A.woff2)
      format("woff2");
  /* latin-ext */
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Helvetica Neue Bold"), local("HelveticaNeue-Bold"),
    url(https://fonts.gstatic.com/s/arimo/v13/P5sBzZCDf9_T_1Wi4TRDrZI.woff2)
      format("woff2");
  /* latin */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "VAG Rounded Std";
  src: url("../fonts/VAGRoundedStd-Bold.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
